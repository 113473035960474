import React from "react"

const Applelogo = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8636 8.50866C13.8733 7.89539 14.0735 7.29408 14.4456 6.76068C14.8177 6.22728 15.3496 5.77913 15.9918 5.45791C15.5839 4.98388 15.0457 4.59377 14.42 4.31857C13.7943 4.04336 13.0983 3.89065 12.3874 3.87255C10.8708 3.74304 9.40062 4.61086 8.62781 4.61086C7.84005 4.61086 6.65021 3.88541 5.36893 3.90686C4.54017 3.92864 3.73247 4.12471 3.02455 4.47597C2.31662 4.82722 1.7326 5.32168 1.32939 5.91117C-0.417185 8.37143 0.885604 11.9872 2.55871 13.9759C3.3958 14.9497 4.37411 16.0375 5.65423 15.9989C6.9069 15.9566 7.37475 15.349 8.88682 15.349C10.3849 15.349 10.8238 15.9989 12.1299 15.9744C13.4741 15.9566 14.321 14.9962 15.1287 14.0132C15.7302 13.3193 16.193 12.5525 16.5 11.741C15.7191 11.4723 15.0526 11.0225 14.5838 10.4477C14.115 9.87286 13.8645 9.1985 13.8636 8.50866Z"
        fill="black"
      />
      <path
        d="M11.3929 2.56475C12.1258 1.84895 12.4869 0.928903 12.3995 0C11.2798 0.0956794 10.2455 0.531064 9.50267 1.2194C9.1395 1.55568 8.86134 1.94689 8.68411 2.37067C8.50688 2.79445 8.43404 3.2425 8.46978 3.6892C9.02983 3.69389 9.58389 3.59513 10.0902 3.40036C10.5966 3.20558 11.042 2.91988 11.3929 2.56475Z"
        fill="black"
      />
    </svg>
  )
}

export default Applelogo
