import React from "react";

const TierBadge = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="20" viewBox="0 0 12 20" fill="none">
      <path d="M5.46875 7.38477H8.0064V11.1629H5.46875V7.38477Z" fill="#E5E4DF" />
      <path
        d="M11.1701 0H2.30613C2.07351 0 1.86719 0.169863 1.86719 0.402483V4.6974C1.86719 4.78237 1.90185 4.86542 1.94988 4.9355L4.3743 8.46427C4.45279 8.57886 4.58727 8.64522 4.72607 8.64522H4.99359L6.78215 8.30257L8.48262 8.64522H8.7501C8.88891 8.64522 9.01894 8.57886 9.09743 8.46427L11.5183 4.93446C11.5665 4.86438 11.5931 4.78237 11.5931 4.6974V0.402483C11.5931 0.169863 11.4026 0 11.1701 0Z"
        fill="#062983"
      />
      <path d="M4.99219 0H8.47429V8.64522H4.99219V0Z" fill="#C6C6C6" />
      <path
        d="M6.74058 20.0007C9.64782 20.0007 12.0046 17.6439 12.0046 14.7367C12.0046 11.8294 9.64782 9.47266 6.74058 9.47266C3.83334 9.47266 1.47656 11.8294 1.47656 14.7367C1.47656 17.6439 3.83334 20.0007 6.74058 20.0007Z"
        fill="#FFD60D"
      />
      <path
        d="M0.571458 11.8142C1.56194 12.0651 2.34161 12.8448 2.59252 13.8352C2.61665 13.9305 2.73925 13.9305 2.76338 13.8352C3.01429 12.8448 3.79396 12.0651 4.78444 11.8142C4.8797 11.7901 4.8797 11.6675 4.78444 11.6434C3.79396 11.3925 3.01429 10.6127 2.76338 9.62222C2.73925 9.52697 2.61665 9.52697 2.59252 9.62222C2.34161 10.6127 1.56194 11.3925 0.571458 11.6434C0.476161 11.6675 0.476201 11.7901 0.571458 11.8142Z"
        fill="#245EF5"
      />
    </svg>
  );
};

export default TierBadge;
