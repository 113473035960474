import React from "react"

const CardsSvg = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_452_520)">
        <path
          d="M0 15.4545C0 16.1296 0.268181 16.7771 0.745546 17.2545C1.22291 17.7318 1.87036 18 2.54545 18H17.8182C18.4933 18 19.1407 17.7318 19.6181 17.2545C20.0955 16.7771 20.3636 16.1296 20.3636 15.4545V8.45455H0V15.4545ZM3 12C3 11.6383 3.14367 11.2915 3.3994 11.0358C3.65513 10.78 4.00198 10.6364 4.36364 10.6364H6.54546C6.90711 10.6364 7.25396 10.78 7.50969 11.0358C7.76542 11.2915 7.90909 11.6383 7.90909 12V12.9091C7.90909 13.2708 7.76542 13.6176 7.50969 13.8733C7.25396 14.1291 6.90711 14.2727 6.54546 14.2727H4.36364C4.00198 14.2727 3.65513 14.1291 3.3994 13.8733C3.14367 13.6176 3 13.2708 3 12.9091V12ZM17.8182 2H2.54545C1.87036 2 1.22291 2.26818 0.745546 2.74555C0.268181 3.22291 0 3.87036 0 4.54545V5.72727H20.3636V4.54545C20.3636 3.87036 20.0955 3.22291 19.6181 2.74555C19.1407 2.26818 18.4933 2 17.8182 2Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_452_520">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CardsSvg
