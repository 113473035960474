import React from "react";

const Nepa = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.37072 11.1425C2.85172 11.7305 4.07572 13.909 4.33572 15H11.3357C11.5957 13.91 12.8182 11.7315 13.2992 11.144C14.1212 10.1413 14.6386 8.92412 14.7902 7.6365C14.9398 6.35136 14.7161 5.05033 14.1457 3.889C13.57 2.71904 12.6748 1.73598 11.5637 1.0535C10.4429 0.364091 9.15263 -0.000617445 7.83672 7.84681e-07C6.51822 7.84681e-07 5.22622 0.365001 4.10972 1.0525C2.99836 1.73472 2.10283 2.71761 1.52672 3.8875C0.956072 5.04838 0.731802 6.34904 0.880723 7.634C1.03022 8.9175 1.54672 10.134 2.37072 11.1425ZM8.33572 4L5.33572 8.5H7.33572V11.5L10.3357 7H8.33572V4Z"
        fill="#FFB800"
      />
      <path
        d="M4.33594 16.5C4.33594 16.3674 4.38862 16.2402 4.48238 16.1464C4.57615 16.0527 4.70333 16 4.83594 16H10.8359C10.9685 16 11.0957 16.0527 11.1895 16.1464C11.2833 16.2402 11.3359 16.3674 11.3359 16.5C11.3359 16.6326 11.2833 16.7598 11.1895 16.8536C11.0957 16.9473 10.9685 17 10.8359 17H4.83594C4.70333 17 4.57615 16.9473 4.48238 16.8536C4.38862 16.7598 4.33594 16.6326 4.33594 16.5ZM11.3359 18H4.33594V19C4.33594 19.2652 4.44129 19.5196 4.62883 19.7071C4.81637 19.8946 5.07072 20 5.33594 20H10.3359C10.6012 20 10.8555 19.8946 11.043 19.7071C11.2306 19.5196 11.3359 19.2652 11.3359 19V18Z"
        fill="#FFB800"
      />
    </svg>
  );
};

export default Nepa;
