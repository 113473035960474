import React from "react"

const Transactsmall = ({ color }) => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.85714 0.5C1.3646 0.5 0.892226 0.695663 0.543944 1.04394C0.195663 1.39223 0 1.8646 0 2.35714V14.6429C0 15.1354 0.195663 15.6078 0.543944 15.9561C0.892226 16.3043 1.3646 16.5 1.85714 16.5H11.2857C11.7783 16.5 12.2506 16.3043 12.5989 15.9561C12.9472 15.6078 13.1429 15.1354 13.1429 14.6429V2.35714C13.1429 1.8646 12.9472 1.39223 12.5989 1.04394C12.2506 0.695663 11.7783 0.5 11.2857 0.5H1.85714ZM3.42857 3.35714H9.71428C9.86584 3.35714 10.0112 3.41735 10.1183 3.52451C10.2255 3.63167 10.2857 3.77702 10.2857 3.92857V5.07143C10.2857 5.22298 10.2255 5.36833 10.1183 5.47549C10.0112 5.58265 9.86584 5.64286 9.71428 5.64286H3.42857C3.27702 5.64286 3.13167 5.58265 3.02451 5.47549C2.91735 5.36833 2.85714 5.22298 2.85714 5.07143V3.92857C2.85714 3.77702 2.91735 3.63167 3.02451 3.52451C3.13167 3.41735 3.27702 3.35714 3.42857 3.35714ZM13.7143 3.92857H14.4286C14.5422 3.92857 14.6512 3.97372 14.7316 4.0541C14.812 4.13447 14.8571 4.24348 14.8571 4.35714V5.78571C14.8571 5.89938 14.812 6.00839 14.7316 6.08876C14.6512 6.16913 14.5422 6.21429 14.4286 6.21429H13.7143V3.92857ZM14.4286 7.35714H13.7143V9.64286H14.4286C14.5422 9.64286 14.6512 9.5977 14.7316 9.51733C14.812 9.43696 14.8571 9.32795 14.8571 9.21429V7.78571C14.8571 7.67205 14.812 7.56304 14.7316 7.48267C14.6512 7.4023 14.5422 7.35714 14.4286 7.35714ZM13.7143 10.7857H14.4286C14.5422 10.7857 14.6512 10.8309 14.7316 10.9112C14.812 10.9916 14.8571 11.1006 14.8571 11.2143V12.6429C14.8571 12.7565 14.812 12.8655 14.7316 12.9459C14.6512 13.0263 14.5422 13.0714 14.4286 13.0714H13.7143V10.7857Z"
        fill={color}
      />
    </svg>
  )
}

export default Transactsmall
