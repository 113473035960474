import React from "react"

const Platformbadgesvg = () => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.0002 39.374H35.7864L12.1614 34.874C11.9014 34.8238 11.6675 34.6834 11.5008 34.4776C11.3341 34.2718 11.2454 34.0138 11.2502 33.749V26.999C11.2471 26.8315 11.2816 26.6653 11.3509 26.5128C11.4203 26.3602 11.5229 26.2251 11.6512 26.1172C11.7796 26.0094 11.9303 25.9316 12.0926 25.8895C12.2548 25.8475 12.4244 25.8422 12.5889 25.874L36.0002 30.374L59.4114 25.874C59.576 25.8422 59.7456 25.8475 59.9078 25.8895C60.07 25.9316 60.2208 26.0094 60.3491 26.1172C60.4774 26.2251 60.58 26.3602 60.6494 26.5128C60.7188 26.6653 60.7532 26.8315 60.7502 26.999V33.749C60.755 34.0138 60.6663 34.2718 60.4996 34.4776C60.3329 34.6834 60.0989 34.8238 59.8389 34.874L36.2139 39.374H36.0002ZM13.5002 32.8153L36.0002 37.124L58.5002 32.8378V28.3378L36.2139 32.624C36.0735 32.6579 35.9269 32.6579 35.7864 32.624L13.5002 28.3603V32.8153Z"
        fill="#5A5A5A"
      />
      <path
        d="M36 68.625C35.8574 68.6271 35.7158 68.6003 35.5837 68.5462L7.45875 57.2962C7.24943 57.2129 7.06996 57.0686 6.94359 56.882C6.81722 56.6955 6.74978 56.4753 6.75 56.25V4.5C6.75 4.20163 6.86853 3.91548 7.07951 3.7045C7.29048 3.49353 7.57663 3.375 7.875 3.375H64.125C64.4234 3.375 64.7095 3.49353 64.9205 3.7045C65.1315 3.91548 65.25 4.20163 65.25 4.5V56.25C65.2502 56.4753 65.1828 56.6955 65.0564 56.882C64.93 57.0686 64.7506 57.2129 64.5412 57.2962L36.4162 68.5462C36.2842 68.6003 36.1426 68.6271 36 68.625ZM9 55.485L36 66.285L63 55.485V5.625H9V55.485Z"
        fill="#5A5A5A"
      />
      <path
        d="M36.0002 55.124H35.7864L12.1614 50.624C11.9014 50.5738 11.6675 50.4334 11.5008 50.2276C11.3341 50.0218 11.2454 49.7638 11.2502 49.499V42.749C11.2471 42.5815 11.2816 42.4153 11.3509 42.2628C11.4203 42.1102 11.5229 41.9751 11.6512 41.8672C11.7796 41.7594 11.9303 41.6816 12.0926 41.6395C12.2548 41.5975 12.4244 41.5922 12.5889 41.624L36.0002 46.124L59.4114 41.624C59.576 41.5922 59.7456 41.5975 59.9078 41.6395C60.07 41.6816 60.2208 41.7594 60.3491 41.8672C60.4774 41.9751 60.58 42.1102 60.6494 42.2628C60.7188 42.4153 60.7532 42.5815 60.7502 42.749V49.499C60.755 49.7638 60.6663 50.0218 60.4996 50.2276C60.3329 50.4334 60.0989 50.5738 59.8389 50.624L36.2139 55.124H36.0002Z"
        fill="#CCED00"
      />
      <path
        d="M13.5002 48.5653L36.0002 52.874L58.5002 48.5878V44.0878L36.2139 48.374C36.0735 48.4079 35.9269 48.4079 35.7864 48.374L13.5002 44.1103V48.5653ZM36.0002 23.624H35.7864L12.1614 19.124C11.9014 19.0738 11.6675 18.9334 11.5008 18.7276C11.3341 18.5218 11.2454 18.2638 11.2502 17.999V11.249C11.2471 11.0815 11.2816 10.9153 11.3509 10.7628C11.4203 10.6102 11.5229 10.4751 11.6512 10.3672C11.7796 10.2594 11.9303 10.1816 12.0926 10.1395C12.2548 10.0975 12.4244 10.0922 12.5889 10.124L36.0002 14.624L59.4114 10.124C59.576 10.0922 59.7456 10.0975 59.9078 10.1395C60.07 10.1816 60.2208 10.2594 60.3491 10.3672C60.4774 10.4751 60.58 10.6102 60.6494 10.7628C60.7188 10.9153 60.7532 11.0815 60.7502 11.249V17.999C60.755 18.2638 60.6663 18.5218 60.4996 18.7276C60.3329 18.9334 60.0989 19.0738 59.8389 19.124L36.2139 23.624H36.0002Z"
        fill="#CCED00"
      />
      <path
        d="M13.5 17.0654L36 21.3741L58.5 17.0879V12.5879L36.2137 16.8741C36.0733 16.908 35.9267 16.908 35.7862 16.8741L13.5 12.6104V17.0654Z"
        fill="#CCED00"
      />
    </svg>
  )
}

export default Platformbadgesvg
