import React from "react"
import Paymenrs from "../../../components/screen-layout/payments"
import "./index.css"
const AdminCustomManagement = () => {
  return (
    <div className="admin-alignment">
      <div>
        <Paymenrs />
      </div>
    </div>
  )
}

export default AdminCustomManagement
