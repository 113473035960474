import React from "react";

const OpenEye = ({ action, color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={action}>
      <path
        d="M12 9.68182C11.349 9.68182 10.7247 9.94042 10.2644 10.4007C9.80406 10.8611 9.54545 11.4854 9.54545 12.1364C9.54545 12.7873 9.80406 13.4117 10.2644 13.872C10.7247 14.3323 11.349 14.5909 12 14.5909C12.651 14.5909 13.2753 14.3323 13.7356 13.872C14.1959 13.4117 14.4545 12.7873 14.4545 12.1364C14.4545 11.4854 14.1959 10.8611 13.7356 10.4007C13.2753 9.94042 12.651 9.68182 12 9.68182ZM12 16.2273C10.915 16.2273 9.87448 15.7963 9.10729 15.0291C8.3401 14.2619 7.90909 13.2213 7.90909 12.1364C7.90909 11.0514 8.3401 10.0108 9.10729 9.24365C9.87448 8.47646 10.915 8.04545 12 8.04545C13.085 8.04545 14.1255 8.47646 14.8927 9.24365C15.6599 10.0108 16.0909 11.0514 16.0909 12.1364C16.0909 13.2213 15.6599 14.2619 14.8927 15.0291C14.1255 15.7963 13.085 16.2273 12 16.2273ZM12 6C7.90909 6 4.41545 8.54455 3 12.1364C4.41545 15.7282 7.90909 18.2727 12 18.2727C16.0909 18.2727 19.5845 15.7282 21 12.1364C19.5845 8.54455 16.0909 6 12 6Z"
        fill={color}
      />
    </svg>
  );
};

export default OpenEye;
