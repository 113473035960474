import React from "react"

const Facebook = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="26"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M8.5 16C12.9183 16 16.5 12.4183 16.5 8C16.5 3.58172 12.9183 0 8.5 0C4.08172 0 0.5 3.58172 0.5 8C0.5 12.4183 4.08172 16 8.5 16Z"
        fill="#062983"
      />
      <path
        d="M10.5095 8.31311H9.08204V13.5428H6.91925V8.31311H5.89062V6.47519H6.91925V5.28584C6.91925 4.43533 7.32326 3.10352 9.10129 3.10352L10.7033 3.11022V4.89424H9.54095C9.35029 4.89424 9.08218 4.9895 9.08218 5.39522V6.4769H10.6985L10.5095 8.31311Z"
        fill="white"
      />
    </svg>
  )
}

export default Facebook
