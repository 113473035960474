import React from "react";
import Onboardinglayout from "../../../utils/onboarding-layout/onboardingLayout";

const AdminLogin = () => {
  return (
    <Onboardinglayout type="admin">
      <h2>Hello World</h2>
      <p>This is a demo for Tobi</p>
    </Onboardinglayout>
  );
};

export default AdminLogin;
