import React from "react";

const Send = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.34602 2.24507C2.49529 2.1154 2.67965 2.03289 2.8758 2.00797C3.07194 1.98306 3.27108 2.01684 3.44802 2.10507L21.448 11.1051C21.6144 11.188 21.7544 11.3157 21.8523 11.4738C21.9501 11.6319 22.002 11.8141 22.002 12.0001C22.002 12.186 21.9501 12.3682 21.8523 12.5263C21.7544 12.6844 21.6144 12.8121 21.448 12.8951L3.44802 21.8951C3.27109 21.9836 3.07187 22.0177 2.87557 21.9929C2.67927 21.9682 2.49472 21.8858 2.34527 21.7562C2.19582 21.6265 2.08818 21.4554 2.03598 21.2646C1.98378 21.0738 1.98937 20.8717 2.05202 20.6841L4.61402 13.0001H10.001C10.2662 13.0001 10.5206 12.8947 10.7081 12.7072C10.8957 12.5196 11.001 12.2653 11.001 12.0001C11.001 11.7348 10.8957 11.4805 10.7081 11.293C10.5206 11.1054 10.2662 11.0001 10.001 11.0001H4.61402L2.05102 3.31607C1.98869 3.12848 1.98335 2.92662 2.03567 2.736C2.088 2.54538 2.19665 2.37454 2.34602 2.24507Z"
        fill="#474747"
      />
    </svg>
  );
};

export default Send;
