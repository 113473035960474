import React from "react"

const AnalyticsSvg = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.493 1.66699H6.50964C3.4763 1.66699 1.66797 3.47533 1.66797 6.50866V13.4837C1.66797 16.5253 3.4763 18.3337 6.50964 18.3337H13.4846C16.518 18.3337 18.3263 16.5253 18.3263 13.492V6.50866C18.3346 3.47533 16.5263 1.66699 13.493 1.66699ZM7.59297 14.0837C7.59297 14.317 7.40964 14.5003 7.1763 14.5003H4.8513C4.61797 14.5003 4.43464 14.317 4.43464 14.0837V10.2337C4.43464 9.70866 4.85964 9.28366 5.38464 9.28366H7.1763C7.40964 9.28366 7.59297 9.46699 7.59297 9.70033V14.0837ZM11.5763 14.0837C11.5763 14.317 11.393 14.5003 11.1596 14.5003H8.83464C8.6013 14.5003 8.41797 14.317 8.41797 14.0837V6.45033C8.41797 5.92533 8.84297 5.50033 9.36797 5.50033H10.6346C11.1596 5.50033 11.5846 5.92533 11.5846 6.45033V14.0837H11.5763ZM15.568 14.0837C15.568 14.317 15.3846 14.5003 15.1513 14.5003H12.8263C12.593 14.5003 12.4096 14.317 12.4096 14.0837V11.1253C12.4096 10.892 12.593 10.7087 12.8263 10.7087H14.618C15.143 10.7087 15.568 11.1337 15.568 11.6587V14.0837Z"
        fill="#5482F7"
      />
    </svg>
  )
}

export default AnalyticsSvg
