import React from "react"

const DepositlargeSvg = ({ color }) => {
  return (
    <svg
      width="157"
      height="157"
      viewBox="0 0 157 157"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.04">
        <path
          d="M43.1853 131.876C55.8741 131.876 66.6317 128.302 73.8003 122.402C73.8003 123.924 73.8003 125.497 73.8003 127.166C73.8003 137.906 60.6406 146.006 43.1853 146.006C25.7301 146.006 12.5703 137.906 12.5703 127.166C12.5703 125.596 12.5703 123.993 12.5703 122.402C19.7389 128.302 30.4966 131.876 43.1853 131.876Z"
          fill={color}
        />
        <path
          d="M73.8003 80.0666C73.8003 90.8069 60.6406 98.9066 43.1853 98.9066C25.7301 98.9066 12.5703 90.8069 12.5703 80.0666C12.5703 69.3262 25.7301 61.2266 43.1853 61.2266C60.6406 61.2266 73.8003 69.3262 73.8003 80.0666Z"
          fill={color}
        />
        <path
          d="M43.1853 108.313C55.8741 108.313 66.6317 104.74 73.8003 98.8398C73.8003 100.361 73.8003 101.934 73.8003 103.603C73.8003 114.344 60.6406 122.443 43.1853 122.443C25.7301 122.443 12.5703 114.344 12.5703 103.603C12.5703 102.033 12.5703 100.43 12.5703 98.8398C19.7389 104.74 30.4966 108.313 43.1853 108.313Z"
          fill={color}
        />
        <path
          d="M113.818 131.876C126.507 131.876 137.265 128.302 144.433 122.402C144.433 123.924 144.433 125.497 144.433 127.166C144.433 137.906 131.273 146.006 113.818 146.006C96.3629 146.006 83.2031 137.906 83.2031 127.166C83.2031 125.596 83.2031 123.993 83.2031 122.402C90.3717 128.302 101.129 131.876 113.818 131.876Z"
          fill={color}
        />
        <path
          d="M144.433 80.0666C144.433 90.8069 131.273 98.9066 113.818 98.9066C96.3629 98.9066 83.2031 90.8069 83.2031 80.0666C83.2031 69.3262 96.3629 61.2266 113.818 61.2266C131.273 61.2266 144.433 69.3262 144.433 80.0666Z"
          fill={color}
        />
        <path
          d="M113.818 108.313C126.507 108.313 137.265 104.74 144.433 98.8398C144.433 100.361 144.433 101.934 144.433 103.603C144.433 114.344 131.273 122.443 113.818 122.443C96.3629 122.443 83.2031 114.344 83.2031 103.603C83.2031 102.033 83.2031 100.43 83.2031 98.8398C90.3717 104.74 101.129 108.313 113.818 108.313Z"
          fill={color}
        />
        <path
          d="M100.658 41.0197L81.8179 59.8597C79.9794 61.6981 76.9964 61.6981 75.158 59.8597L56.318 41.0197C54.9709 39.6726 54.5658 37.6489 55.2975 35.8858C56.0228 34.1274 57.7435 32.9797 59.6479 32.9797H69.0679V18.8497C69.0679 13.6467 73.2849 9.42969 78.4879 9.42969C83.6909 9.42969 87.9079 13.6467 87.9079 18.8497V32.9797H97.3279C99.2323 32.9797 100.953 34.1274 101.678 35.8873C102.41 37.6489 102.005 39.6726 100.658 41.0197Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default DepositlargeSvg
