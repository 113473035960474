import React from "react";

const Verification = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M18.7919 4H6V5.59898L10.645 9.08476C9.23533 9.54961 8.06787 10.5552 7.39924 11.8804C6.73061 13.2057 6.61554 14.7422 7.07931 16.1523C7.30876 16.8508 7.67367 17.4972 8.15317 18.0546C8.63268 18.6119 9.21736 19.0693 9.87378 19.4004C10.5302 19.7316 11.2455 19.9301 11.9787 19.9846C12.7119 20.0391 13.4487 19.9485 14.1468 19.718C15.2665 19.35 16.2414 18.6379 16.9325 17.6832C17.6237 16.7285 17.9958 15.58 17.9958 14.4014C17.9958 13.2228 17.6237 12.0743 16.9325 11.1196C16.2414 10.1649 15.2665 9.45278 14.1468 9.08476L18.7919 5.59898V4ZM14.7464 17.9911L12.3959 16.616L10.0454 17.9911L10.669 15.3288L8.59835 13.5379L11.3246 13.3061L12.3959 10.7957L13.4672 13.3061L16.1935 13.5379L14.1228 15.3288L14.7464 17.9911Z"
        fill="#999999"
      />
    </svg>
  );
};

export default Verification;
