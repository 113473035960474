import React from "react";

const ClosedEye = ({ action, color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={action}>
      <path
        d="M11.8609 8.90909L14.4545 11.4945V11.3636C14.4545 10.7127 14.1959 10.0883 13.7356 9.62801C13.2753 9.16769 12.651 8.90909 12 8.90909H11.8609ZM8.34273 9.56364L9.61091 10.8318C9.57 11.0036 9.54545 11.1755 9.54545 11.3636C9.54545 12.0146 9.80406 12.6389 10.2644 13.0993C10.7247 13.5596 11.349 13.8182 12 13.8182C12.18 13.8182 12.36 13.7936 12.5318 13.7527L13.8 15.0209C13.2518 15.2909 12.6464 15.4545 12 15.4545C10.915 15.4545 9.87448 15.0235 9.10729 14.2563C8.3401 13.4892 7.90909 12.4486 7.90909 11.3636C7.90909 10.7173 8.07273 10.1118 8.34273 9.56364ZM3.81818 5.03909L5.68364 6.90455L6.05182 7.27273C4.70182 8.33636 3.63818 9.72727 3 11.3636C4.41545 14.9555 7.90909 17.5 12 17.5C13.2682 17.5 14.4791 17.2545 15.5836 16.8127L15.9355 17.1564L18.3245 19.5455L19.3636 18.5064L4.85727 4M12 7.27273C13.085 7.27273 14.1255 7.70373 14.8927 8.47093C15.6599 9.23812 16.0909 10.2787 16.0909 11.3636C16.0909 11.8873 15.9845 12.3945 15.7964 12.8527L18.1936 15.25C19.4209 14.2273 20.4027 12.8855 21 11.3636C19.5845 7.77182 16.0909 5.22727 12 5.22727C10.8545 5.22727 9.75818 5.43182 8.72727 5.8L10.5027 7.55909C10.9691 7.37909 11.4682 7.27273 12 7.27273Z"
        fill={color}
      />
    </svg>
  );
};

export default ClosedEye;
