import React from "react";

const Contacts = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
      <path
        opacity="0.4"
        d="M39.9974 6.66602C31.2641 6.66602 24.1641 13.766 24.1641 22.4993C24.1641 31.066 30.8641 37.9993 39.5974 38.2993C39.8641 38.266 40.1307 38.266 40.3307 38.2993C40.3974 38.2993 40.4307 38.2993 40.4974 38.2993C40.5307 38.2993 40.5307 38.2993 40.5641 38.2993C49.0974 37.9993 55.7974 31.066 55.8307 22.4993C55.8307 13.766 48.7307 6.66602 39.9974 6.66602Z"
        fill="white"
      />
      <path
        d="M56.9313 47.1676C47.6313 40.9676 32.4646 40.9676 23.0979 47.1676C18.8646 50.0009 16.5312 53.8342 16.5312 57.9342C16.5312 62.0342 18.8646 65.8342 23.0646 68.6342C27.7313 71.7676 33.8646 73.3343 39.9979 73.3343C46.1313 73.3343 52.2646 71.7676 56.9313 68.6342C61.1313 65.8009 63.4646 62.0009 63.4646 57.8676C63.4313 53.7676 61.1313 49.9676 56.9313 47.1676Z"
        fill="white"
      />
    </svg>
  );
};

export default Contacts;
