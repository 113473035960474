import React from "react";

const Share = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M13.5757 17.2716L8.46571 14.4846C7.97521 14.9715 7.35153 15.3023 6.6733 15.4352C5.99508 15.5682 5.29267 15.4974 4.65464 15.2317C4.0166 14.966 3.4715 14.5174 3.08806 13.9424C2.70462 13.3674 2.5 12.6917 2.5 12.0006C2.5 11.3095 2.70462 10.6338 3.08806 10.0588C3.4715 9.48378 4.0166 9.03516 4.65464 8.7695C5.29267 8.50383 5.99508 8.43302 6.6733 8.56597C7.35153 8.69893 7.97521 9.0297 8.46571 9.5166L13.5757 6.7296C13.4005 5.90733 13.527 5.04946 13.9323 4.31285C14.3376 3.57625 14.9945 3.01013 15.7828 2.71803C16.5712 2.42593 17.4383 2.42737 18.2257 2.72209C19.0131 3.0168 19.6681 3.58509 20.0709 4.32304C20.4737 5.06098 20.5975 5.91927 20.4195 6.74095C20.2415 7.56263 19.7737 8.29279 19.1016 8.7979C18.4295 9.30301 17.5981 9.54932 16.7593 9.4918C15.9206 9.43427 15.1305 9.07674 14.5337 8.4846L9.42371 11.2716C9.5255 11.7519 9.5255 12.2483 9.42371 12.7286L14.5337 15.5166C15.1305 14.9245 15.9206 14.5669 16.7593 14.5094C17.5981 14.4519 18.4295 14.6982 19.1016 15.2033C19.7737 15.7084 20.2415 16.4386 20.4195 17.2602C20.5975 18.0819 20.4737 18.9402 20.0709 19.6782C19.6681 20.4161 19.0131 20.9844 18.2257 21.2791C17.4383 21.5738 16.5712 21.5753 15.7828 21.2832C14.9945 20.9911 14.3376 20.425 13.9323 19.6883C13.527 18.9517 13.4005 18.0939 13.5757 17.2716Z"
        fill="#474747"
      />
    </svg>
  );
};

export default Share;
