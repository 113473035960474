import React from "react"

const Instagram = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="26"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M8.5 16C12.9183 16 16.5 12.4183 16.5 8C16.5 3.58172 12.9183 0 8.5 0C4.08172 0 0.5 3.58172 0.5 8C0.5 12.4183 4.08172 16 8.5 16Z"
        fill="#062983"
      />
      <path
        d="M10.9019 4.09962H6.10166C5.90445 4.09885 5.70904 4.13712 5.52669 4.21224C5.34434 4.28735 5.17867 4.39782 5.03922 4.53727C4.89977 4.67672 4.7893 4.84239 4.71419 5.02474C4.63908 5.20709 4.6008 5.4025 4.60157 5.59971V10.4C4.6008 10.5972 4.63908 10.7926 4.71419 10.9749C4.7893 11.1573 4.89977 11.323 5.03922 11.4624C5.17867 11.6019 5.34434 11.7123 5.52669 11.7874C5.70904 11.8626 5.90445 11.9008 6.10166 11.9001H10.9019C11.0991 11.9008 11.2946 11.8626 11.4769 11.7874C11.6592 11.7123 11.8249 11.6019 11.9644 11.4624C12.1038 11.323 12.2143 11.1573 12.2894 10.9749C12.3645 10.7926 12.4028 10.5972 12.402 10.4V5.59971C12.4028 5.4025 12.3645 5.20709 12.2894 5.02474C12.2143 4.84239 12.1038 4.67672 11.9644 4.53727C11.8249 4.39782 11.6592 4.28735 11.4769 4.21224C11.2946 4.13712 11.0991 4.09885 10.9019 4.09962ZM11.3353 4.99967H11.502V6.33312H10.1685V4.99967H11.3353ZM7.40185 7.19991C7.635 6.86664 8.0684 6.63314 8.5018 6.63314C8.93519 6.63314 9.36859 6.86664 9.60174 7.19991C9.77344 7.43142 9.86688 7.71162 9.8685 7.99984C9.8685 8.36232 9.72451 8.70994 9.4682 8.96625C9.2119 9.22256 8.86427 9.36655 8.5018 9.36655C8.13932 9.36655 7.7917 9.22256 7.53539 8.96625C7.27908 8.70994 7.13509 8.36232 7.13509 7.99984C7.13672 7.71162 7.23016 7.43142 7.40185 7.19991ZM11.6353 10.4C11.6336 10.5939 11.5557 10.7794 11.4185 10.9165C11.2814 11.0536 11.0959 11.1314 10.9019 11.1331H6.10166C5.90773 11.1314 5.72222 11.0536 5.58506 10.9165C5.44789 10.7794 5.37004 10.5939 5.36825 10.4V7.19991H6.53403C6.42766 7.45339 6.37108 7.72498 6.36739 7.99984C6.36739 9.16562 7.33396 10.1332 8.50077 10.1332C9.66758 10.1332 10.6341 9.16665 10.6341 7.99984C10.6305 7.72498 10.5739 7.45339 10.4675 7.19991H11.6333V10.4H11.6353Z"
        fill="white"
      />
    </svg>
  )
}

export default Instagram
