import React from "react";

const SuccessCheck = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="167" height="166" viewBox="0 0 167 166" fill="none">
      <path
        d="M83.5 0C105.513 0 126.624 8.74462 142.19 24.3101C157.755 39.8757 166.5 60.987 166.5 83C166.5 105.013 157.755 126.124 142.19 141.69C126.624 157.255 105.513 166 83.5 166C61.487 166 40.3757 157.255 24.8101 141.69C9.24462 126.124 0.5 105.013 0.5 83C0.5 60.987 9.24462 39.8757 24.8101 24.3101C40.3757 8.74462 61.487 0 83.5 0ZM73.1606 99.3747L54.7227 80.925C54.0617 80.264 53.277 79.7397 52.4134 79.382C51.5498 79.0242 50.6241 78.8401 49.6894 78.8401C48.7546 78.8401 47.8289 79.0242 46.9653 79.382C46.1017 79.7397 45.317 80.264 44.656 80.925C43.3211 82.2599 42.5711 84.0705 42.5711 85.9584C42.5711 87.8462 43.3211 89.6568 44.656 90.9917L68.1331 114.469C68.7923 115.133 69.5764 115.66 70.4403 116.02C71.3041 116.38 72.2307 116.565 73.1665 116.565C74.1023 116.565 75.0289 116.38 75.8927 116.02C76.7566 115.66 77.5407 115.133 78.1999 114.469L126.814 65.8427C127.484 65.1845 128.017 64.4001 128.382 63.5349C128.747 62.6697 128.937 61.7407 128.942 60.8016C128.946 59.8625 128.765 58.9318 128.407 58.0632C128.05 57.1947 127.525 56.4054 126.861 55.7409C126.197 55.0765 125.409 54.55 124.541 54.1919C123.672 53.8337 122.742 53.651 121.803 53.6543C120.864 53.6575 119.935 53.8467 119.069 54.2109C118.203 54.575 117.418 55.107 116.759 55.776L73.1606 99.3747Z"
        fill="#059D02"
      />
    </svg>
  );
};

export default SuccessCheck;
