import React from "react"

const StatementsSvg = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66797 5.83366V17.5003C1.66797 18.192 2.4513 18.5837 3.0013 18.167L4.4263 17.1003C4.75964 16.8503 5.2263 16.8837 5.5263 17.1837L6.90964 18.5753C7.23464 18.9003 7.76797 18.9003 8.09297 18.5753L9.49297 17.1753C9.78464 16.8837 10.2513 16.8503 10.5763 17.1003L12.0013 18.167C12.5513 18.5753 13.3346 18.1837 13.3346 17.5003V3.33366C13.3346 2.41699 14.0846 1.66699 15.0013 1.66699H5.83464H5.0013C2.5013 1.66699 1.66797 3.15866 1.66797 5.00033V5.83366Z"
        fill={color}
      />
      <path
        d="M15.007 1.66699V2.91699C15.557 2.91699 16.082 3.14199 16.4654 3.51699C16.8654 3.92533 17.082 4.45033 17.082 5.00033V7.01699C17.082 7.63366 16.807 7.91699 16.182 7.91699H14.582V3.34199C14.582 3.10866 14.7737 2.91699 15.007 2.91699V1.66699ZM15.007 1.66699C14.082 1.66699 13.332 2.41699 13.332 3.34199V9.16699H16.182C17.4987 9.16699 18.332 8.33366 18.332 7.01699V5.00033C18.332 4.08366 17.957 3.25033 17.357 2.64199C16.7487 2.04199 15.9237 1.67533 15.007 1.66699C15.0154 1.66699 15.007 1.66699 15.007 1.66699Z"
        fill={color}
      />
    </svg>
  )
}

export default StatementsSvg
