import React from "react";

const DashboardSvg = ({ color }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3533_7072)">
        <path
          d="M8.8 1.76667C8.56921 1.59357 8.28849 1.5 8 1.5C7.71151 1.5 7.4308 1.59357 7.2 1.76667L2.53333 5.26667C2.36774 5.39086 2.23333 5.55191 2.14076 5.73705C2.04819 5.92219 2 6.12634 2 6.33333V12.6667C2 13.0203 2.14048 13.3594 2.39052 13.6095C2.64057 13.8595 2.97971 14 3.33333 14H5.93333C6.12783 14 6.31435 13.9227 6.45188 13.7852C6.58941 13.6477 6.66667 13.4612 6.66667 13.2667V10C6.66667 9.64638 6.80714 9.30724 7.05719 9.05719C7.30724 8.80714 7.64638 8.66667 8 8.66667C8.35362 8.66667 8.69276 8.80714 8.94281 9.05719C9.19286 9.30724 9.33333 9.64638 9.33333 10V13.2667C9.33333 13.4612 9.4106 13.6477 9.54812 13.7852C9.68565 13.9227 9.87218 14 10.0667 14H12.6667C13.0203 14 13.3594 13.8595 13.6095 13.6095C13.8595 13.3594 14 13.0203 14 12.6667V6.33333C14 6.12634 13.9518 5.92219 13.8592 5.73705C13.7667 5.55191 13.6323 5.39086 13.4667 5.26667L8.8 1.76667Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3533_7072">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DashboardSvg;
