import React from "react";

const ArrowDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M18.8054 14.3866L12.3261 20.866C12.2257 20.9666 12.1065 21.0465 11.9753 21.101C11.844 21.1555 11.7033 21.1836 11.5611 21.1836C11.419 21.1836 11.2783 21.1555 11.147 21.101C11.0158 21.0465 10.8965 20.9666 10.7962 20.866L4.31684 14.3866C4.11397 14.1837 4 13.9086 4 13.6217C4 13.3348 4.11397 13.0596 4.31684 12.8568C4.51971 12.6539 4.79487 12.5399 5.08177 12.5399C5.36867 12.5399 5.64382 12.6539 5.84669 12.8568L10.4821 17.4922V4.26349C10.4821 3.97708 10.5959 3.70241 10.7984 3.49989C11.001 3.29737 11.2756 3.18359 11.562 3.18359C11.8484 3.18359 12.1231 3.29737 12.3256 3.49989C12.5282 3.70241 12.6419 3.97708 12.6419 4.26349V17.4922L17.2774 12.8559C17.4803 12.653 17.7554 12.539 18.0423 12.539C18.3292 12.539 18.6044 12.653 18.8072 12.8559C19.0101 13.0587 19.1241 13.3339 19.1241 13.6208C19.1241 13.9077 19.0101 14.1828 18.8072 14.3857L18.8054 14.3866Z"
        fill="#851188"
      />
    </svg>
  );
};

export default ArrowDown;
