import React from "react";

const Close = ({ action, color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={action}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.91681 6.33026C7.70355 6.13154 7.42148 6.02335 7.13003 6.0285C6.83858 6.03364 6.5605 6.15171 6.35438 6.35783C6.14826 6.56394 6.03019 6.84202 6.02505 7.13347C6.01991 7.42493 6.12809 7.707 6.32681 7.92026L10.4068 12.0003L6.32681 16.0803C6.21628 16.1833 6.12763 16.3075 6.06614 16.4455C6.00465 16.5834 5.97159 16.7324 5.96893 16.8835C5.96626 17.0345 5.99405 17.1846 6.05063 17.3247C6.10721 17.4647 6.19143 17.592 6.29825 17.6988C6.40508 17.8056 6.53233 17.8899 6.67242 17.9464C6.8125 18.003 6.96254 18.0308 7.1136 18.0281C7.26465 18.0255 7.41362 17.9924 7.55162 17.9309C7.68962 17.8694 7.81382 17.7808 7.91681 17.6703L11.9968 13.5903L16.0768 17.6703C16.1798 17.7808 16.304 17.8694 16.442 17.9309C16.58 17.9924 16.729 18.0255 16.88 18.0281C17.0311 18.0308 17.1811 18.003 17.3212 17.9464C17.4613 17.8899 17.5885 17.8056 17.6954 17.6988C17.8022 17.592 17.8864 17.4647 17.943 17.3247C17.9996 17.1846 18.0274 17.0345 18.0247 16.8835C18.022 16.7324 17.989 16.5834 17.9275 16.4455C17.866 16.3075 17.7773 16.1833 17.6668 16.0803L13.5868 12.0003L17.6668 7.92026C17.8655 7.707 17.9737 7.42493 17.9686 7.13347C17.9634 6.84202 17.8454 6.56394 17.6392 6.35783C17.4331 6.15171 17.155 6.03364 16.8636 6.0285C16.5721 6.02335 16.2901 6.13154 16.0768 6.33026L11.9968 10.4103L7.91681 6.33026Z"
        fill={color}
      />
    </svg>
  );
};

export default Close;
