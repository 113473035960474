import React from "react";

const Block = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M18.3641 5.63587C16.6642 3.936 14.4041 3 12 3C9.59587 3 7.33575 3.936 5.63587 5.63587C3.936 7.33575 3 9.59587 3 12C3 14.4041 3.936 16.6642 5.63587 18.3641C7.33575 20.064 9.59587 21 12 21C14.4041 21 16.6642 20.064 18.3641 18.3641C20.064 16.6642 21 14.4041 21 12C21 9.59587 20.064 7.33575 18.3641 5.63587ZM18.75 12C18.7515 13.4016 18.3145 14.7686 17.5001 15.9094L8.09063 6.49988C9.23141 5.68553 10.5984 5.24849 12 5.25C15.7215 5.25 18.75 8.2785 18.75 12ZM5.25 12C5.24849 10.5984 5.68553 9.23141 6.49988 8.09063L15.9094 17.5001C14.7686 18.3145 13.4016 18.7515 12 18.75C8.2785 18.75 5.25 15.7215 5.25 12Z"
        fill="#999999"
      />
    </svg>
  );
};

export default Block;
