import React from "react";

const Data = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <g clipPath="url(#clip0_3596_4993)">
        <path
          d="M12.3699 3.00002C7.40405 3.00002 2.91465 5.01179 -0.335938 8.27296L12.3699 21L25.0758 8.27296C23.4112 6.59834 21.4313 5.27014 19.2505 4.36509C17.0697 3.46004 14.7311 2.99609 12.3699 3.00002ZM12.3699 7.23531C9.3417 7.23531 6.54641 8.23061 4.26994 9.89296L2.75582 8.36825C5.49817 6.2612 8.84405 5.11767 12.3699 5.11767C15.8958 5.11767 19.2417 6.2612 21.984 8.36825L20.4699 9.88237C18.1182 8.16476 15.2821 7.23791 12.3699 7.23531Z"
          fill="#FFB800"
        />
      </g>
      <defs>
        <clipPath id="clip0_3596_4993">
          <rect width="24" height="24" fill="white" transform="translate(0.664062)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Data;
