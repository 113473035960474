import React from "react";

const Copy = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.38 3H11.9187C10.3507 3 9.108 3 8.13644 3.13156C7.13556 3.26667 6.32578 3.55111 5.68756 4.192C5.04844 4.83289 4.76489 5.64622 4.63067 6.65067C4.5 7.62667 4.5 8.87378 4.5 10.448V15.6373C4.5 16.9778 5.31778 18.1262 6.47956 18.608C6.42 17.7991 6.42 16.6658 6.42 15.7218V11.2684C6.42 10.1298 6.42 9.14756 6.52489 8.36178C6.63778 7.51911 6.892 6.712 7.54444 6.05689C8.19689 5.40178 9.00133 5.14667 9.84045 5.03289C10.6227 4.928 11.6004 4.928 12.7356 4.928H15.4644C16.5987 4.928 17.5747 4.928 18.3578 5.03289C18.1231 4.43411 17.7135 3.91991 17.1823 3.5573C16.6512 3.19468 16.0231 3.00048 15.38 3Z"
        fill="#E6E6E6"
      />
      <path
        d="M7.70312 11.3533C7.70312 8.93022 7.70312 7.71867 8.45335 6.96578C9.20268 6.21289 10.4089 6.21289 12.8231 6.21289H15.3831C17.7965 6.21289 19.0036 6.21289 19.7538 6.96578C20.5031 7.71867 20.5031 8.93022 20.5031 11.3533V15.6378C20.5031 18.0609 20.5031 19.2724 19.7538 20.0253C19.0036 20.7782 17.7965 20.7782 15.3831 20.7782H12.8231C10.4098 20.7782 9.20268 20.7782 8.45335 20.0253C7.70312 19.2724 7.70312 18.0609 7.70312 15.6378V11.3533Z"
        fill="#E6E6E6"
      />
    </svg>
  );
};

export default Copy;
