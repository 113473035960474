import bankLogo from "../../assets/ecobnkIcon.png"
export const dashsidejsonData = [
  {
    imageSrc: bankLogo,
    title: "Abraham David",
    subtitle: "Mar 5th, 10:27",
    date: "-N900",
    rate: "Successful",
  },
  {
    imageSrc: bankLogo,
    title: "Abraham David",
    subtitle: "Mar 5th, 10:27",
    date: "-N900",
    rate: "Successful",
  },
  {
    imageSrc: bankLogo,
    title: "Abraham David",
    subtitle: "Mar 5th, 10:27",
    date: "-N900",
    rate: "Successful",
  },
  {
    imageSrc: bankLogo,
    title: "Abraham David",
    subtitle: "Mar 5th, 10:27",
    date: "-N900",
    rate: "Successful",
  },
  {
    imageSrc: bankLogo,
    title: "Abraham David",
    subtitle: "Mar 5th, 10:27",
    date: "-N900",
    rate: "Successful",
  },
]
