import React from "react";

const User = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M21.3307 4.62368C23.3064 5.60548 24.6641 7.64419 24.6641 10C24.6641 12.3558 23.3064 14.3945 21.3307 15.3763M23.9974 22.3552C26.0127 23.2671 27.8274 24.7533 29.3307 26.6667M2.66406 26.6667C5.25938 23.3634 8.78297 21.3333 12.6641 21.3333C16.5452 21.3333 20.0687 23.3634 22.6641 26.6667M18.6641 10C18.6641 13.3137 15.9778 16 12.6641 16C9.35035 16 6.66406 13.3137 6.66406 10C6.66406 6.68629 9.35035 4 12.6641 4C15.9778 4 18.6641 6.68629 18.6641 10Z"
        stroke="#666666"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default User;
