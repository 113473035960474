import React from "react";

const ProfileSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        opacity="0.4"
        d="M12 2C9.38 2 7.25 4.13 7.25 6.75C7.25 9.32 9.26 11.4 11.88 11.49C11.96 11.48 12.04 11.48 12.1 11.49C12.12 11.49 12.13 11.49 12.15 11.49C12.16 11.49 12.16 11.49 12.17 11.49C14.73 11.4 16.74 9.32 16.75 6.75C16.75 4.13 14.62 2 12 2Z"
        fill="white"
      />
      <path
        d="M17.0809 14.1509C14.2909 12.2909 9.74094 12.2909 6.93094 14.1509C5.66094 15.0009 4.96094 16.1509 4.96094 17.3809C4.96094 18.6109 5.66094 19.7509 6.92094 20.5909C8.32094 21.5309 10.1609 22.0009 12.0009 22.0009C13.8409 22.0009 15.6809 21.5309 17.0809 20.5909C18.3409 19.7409 19.0409 18.6009 19.0409 17.3609C19.0309 16.1309 18.3409 14.9909 17.0809 14.1509Z"
        fill="white"
      />
    </svg>
  );
};

export default ProfileSvg;
