import React from "react"

const TransactlargeSvg = ({ color }) => {
  return (
    <svg
      width="98"
      height="106"
      viewBox="0 0 98 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3676 0.679688C9.14549 0.679688 6.0554 1.95965 3.77705 4.23799C1.49871 6.51634 0.21875 9.60643 0.21875 12.8285V93.1976C0.21875 96.4196 1.49871 99.5097 3.77705 101.788C6.0554 104.066 9.14549 105.346 12.3676 105.346H74.0461C77.2682 105.346 80.3583 104.066 82.6366 101.788C84.915 99.5097 86.1949 96.4196 86.1949 93.1976V12.8285C86.1949 9.60643 84.915 6.51634 82.6366 4.23799C80.3583 1.95965 77.2682 0.679688 74.0461 0.679688H12.3676ZM22.6473 19.3702H63.7664C64.7578 19.3702 65.7086 19.764 66.4096 20.465C67.1106 21.1661 67.5045 22.1169 67.5045 23.1083V30.5845C67.5045 31.5759 67.1106 32.5267 66.4096 33.2277C65.7086 33.9287 64.7578 34.3225 63.7664 34.3225H22.6473C21.6559 34.3225 20.7051 33.9287 20.0041 33.2277C19.3031 32.5267 18.9092 31.5759 18.9092 30.5845V23.1083C18.9092 22.1169 19.3031 21.1661 20.0041 20.465C20.7051 19.764 21.6559 19.3702 22.6473 19.3702ZM89.933 23.1083H94.6057C95.3492 23.1083 96.0623 23.4036 96.5881 23.9294C97.1139 24.4552 97.4092 25.1683 97.4092 25.9118V35.2571C97.4092 36.0006 97.1139 36.7137 96.5881 37.2395C96.0623 37.7653 95.3492 38.0606 94.6057 38.0606H89.933V23.1083ZM94.6057 45.5368H89.933V60.4892H94.6057C95.3492 60.4892 96.0623 60.1938 96.5881 59.6681C97.1139 59.1423 97.4092 58.4292 97.4092 57.6856V48.3404C97.4092 47.5969 97.1139 46.8838 96.5881 46.358C96.0623 45.8322 95.3492 45.5368 94.6057 45.5368ZM89.933 67.9654H94.6057C95.3492 67.9654 96.0623 68.2608 96.5881 68.7866C97.1139 69.3123 97.4092 70.0254 97.4092 70.769V80.1142C97.4092 80.8578 97.1139 81.5709 96.5881 82.0966C96.0623 82.6224 95.3492 82.9178 94.6057 82.9178H89.933V67.9654Z"
        fill={color}
      />
    </svg>
  )
}

export default TransactlargeSvg
