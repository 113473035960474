import React from "react";

const Bell = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M19.7966 15.8085C19.7225 15.7192 19.6498 15.6299 19.5783 15.5438C18.5962 14.3558 18.002 13.6388 18.002 10.2759C18.002 8.53482 17.5855 7.10625 16.7645 6.03482C16.1591 5.2433 15.3408 4.64286 14.2623 4.19911C14.2484 4.19139 14.236 4.18126 14.2256 4.1692C13.8377 2.87009 12.7761 2 11.5788 2C10.3814 2 9.32029 2.87009 8.93234 4.16786C8.92199 4.17948 8.90977 4.18928 8.89618 4.19688C6.37922 5.23304 5.156 7.22098 5.156 10.2746C5.156 13.6388 4.5627 14.3558 3.57966 15.5424C3.50823 15.6286 3.43547 15.7161 3.36136 15.8071C3.16993 16.038 3.04864 16.3189 3.01186 16.6165C2.97507 16.9141 3.02431 17.2161 3.15377 17.4866C3.42922 18.067 4.01627 18.4272 4.68636 18.4272H18.4761C19.1431 18.4272 19.7261 18.0674 20.0024 17.4897C20.1324 17.2191 20.1822 16.917 20.1457 16.619C20.1092 16.321 19.9881 16.0397 19.7966 15.8085ZM11.5788 22C12.2239 21.9995 12.8568 21.8244 13.4104 21.4932C13.9641 21.1621 14.4178 20.6873 14.7234 20.1192C14.7378 20.092 14.7449 20.0615 14.7441 20.0307C14.7432 19.9999 14.7344 19.9699 14.7185 19.9435C14.7026 19.9171 14.6801 19.8953 14.6533 19.8802C14.6265 19.865 14.5962 19.8571 14.5654 19.8571H8.59306C8.56222 19.857 8.53188 19.8649 8.50499 19.88C8.4781 19.8951 8.45559 19.9169 8.43963 19.9433C8.42367 19.9697 8.41482 19.9998 8.41393 20.0306C8.41305 20.0614 8.42016 20.0919 8.43457 20.1192C8.74017 20.6872 9.19379 21.162 9.74734 21.4931C10.3009 21.8242 10.9337 21.9994 11.5788 22Z"
        fill="#474747"
      />
    </svg>
  );
};

export default Bell;
