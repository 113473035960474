import React from "react";

const Decline = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M21.918 17.5695C21.8165 17.8138 21.6223 18.008 21.378 18.1095C21.2578 18.1607 21.1287 18.1879 20.998 18.1895H15.998C15.7328 18.1895 15.4784 18.0841 15.2909 17.8966C15.1033 17.709 14.998 17.4547 14.998 17.1895C14.998 16.9242 15.1033 16.6699 15.2909 16.4823C15.4784 16.2948 15.7328 16.1895 15.998 16.1895H18.588L12.998 10.5995L9.70799 13.8995C9.61502 13.9932 9.50442 14.0676 9.38256 14.1183C9.2607 14.1691 9.13 14.1953 8.99799 14.1953C8.86597 14.1953 8.73527 14.1691 8.61341 14.1183C8.49155 14.0676 8.38095 13.9932 8.28799 13.8995L2.28799 7.89945C2.19426 7.80649 2.11986 7.69589 2.06909 7.57403C2.01833 7.45217 1.99219 7.32146 1.99219 7.18945C1.99219 7.05744 2.01833 6.92674 2.06909 6.80488C2.11986 6.68302 2.19426 6.57242 2.28799 6.47945C2.38095 6.38573 2.49155 6.31133 2.61341 6.26056C2.73527 6.20979 2.86597 6.18365 2.99799 6.18365C3.13 6.18365 3.2607 6.20979 3.38256 6.26056C3.50442 6.31133 3.61502 6.38573 3.70799 6.47945L8.99799 11.7795L12.288 8.47945C12.3809 8.38573 12.4915 8.31133 12.6134 8.26056C12.7353 8.20979 12.866 8.18365 12.998 8.18365C13.13 8.18365 13.2607 8.20979 13.3826 8.26056C13.5044 8.31133 13.615 8.38573 13.708 8.47945L19.998 14.7795V12.1895C19.998 11.9242 20.1033 11.6699 20.2909 11.4823C20.4784 11.2948 20.7328 11.1895 20.998 11.1895C21.2632 11.1895 21.5176 11.2948 21.7051 11.4823C21.8926 11.6699 21.998 11.9242 21.998 12.1895V17.1895C21.9964 17.3201 21.9692 17.4492 21.918 17.5695Z"
        fill="#C52D26"
      />
    </svg>
  );
};

export default Decline;
