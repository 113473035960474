import React from "react";

const Search = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M10.1827 3C9.05853 3.0006 7.95016 3.26493 6.9467 3.77174C5.94323 4.27854 5.07266 5.0137 4.40495 5.91811C3.73724 6.82252 3.29102 7.87096 3.10215 8.97917C2.91328 10.0874 2.98703 11.2244 3.31747 12.299C3.64791 13.3735 4.22583 14.3555 5.00477 15.1661C5.78372 15.9767 6.74196 16.5932 7.80249 16.9661C8.86301 17.3391 9.99624 17.458 11.1111 17.3134C12.2259 17.1688 13.2913 16.7646 14.2216 16.1334L18.6628 20.5711C18.7862 20.7035 18.935 20.8097 19.1003 20.8833C19.2656 20.957 19.444 20.9966 19.6249 20.9998C19.8058 21.003 19.9855 20.9697 20.1533 20.9019C20.3211 20.8342 20.4735 20.7333 20.6015 20.6053C20.7294 20.4774 20.8303 20.325 20.8981 20.1572C20.9659 19.9894 20.9991 19.8097 20.9959 19.6287C20.9928 19.4478 20.9532 19.2694 20.8795 19.1041C20.8059 18.9388 20.6997 18.7901 20.5673 18.6667L16.1296 14.2254C16.8638 13.1449 17.2895 11.8847 17.3611 10.5803C17.4327 9.27587 17.1473 7.97665 16.5358 6.82228C15.9242 5.66791 15.0096 4.70207 13.8902 4.02861C12.7708 3.35514 11.4891 2.99953 10.1827 3ZM5.69112 10.1866C5.69112 8.99531 6.16434 7.85285 7.00668 7.01052C7.84901 6.16818 8.99147 5.69496 10.1827 5.69496C11.374 5.69496 12.5164 6.16818 13.3588 7.01052C14.2011 7.85285 14.6743 8.99531 14.6743 10.1866C14.6743 11.3778 14.2011 12.5203 13.3588 13.3626C12.5164 14.2049 11.374 14.6782 10.1827 14.6782C8.99147 14.6782 7.84901 14.2049 7.00668 13.3626C6.16434 12.5203 5.69112 11.3778 5.69112 10.1866Z"
        fill="#474747"
      />
    </svg>
  );
};

export default Search;
