import React from "react";

const StatementSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M6.85714 4C6.3646 4 5.89223 4.19566 5.54394 4.54394C5.19566 4.89223 5 5.3646 5 5.85714V18.1429C5 18.6354 5.19566 19.1078 5.54394 19.4561C5.89223 19.8043 6.3646 20 6.85714 20H16.2857C16.7783 20 17.2506 19.8043 17.5989 19.4561C17.9472 19.1078 18.1429 18.6354 18.1429 18.1429V5.85714C18.1429 5.3646 17.9472 4.89223 17.5989 4.54394C17.2506 4.19566 16.7783 4 16.2857 4H6.85714ZM8.42857 6.85714H14.7143C14.8658 6.85714 15.0112 6.91735 15.1183 7.02451C15.2255 7.13167 15.2857 7.27702 15.2857 7.42857V8.57143C15.2857 8.72298 15.2255 8.86833 15.1183 8.97549C15.0112 9.08265 14.8658 9.14286 14.7143 9.14286H8.42857C8.27702 9.14286 8.13167 9.08265 8.02451 8.97549C7.91735 8.86833 7.85714 8.72298 7.85714 8.57143V7.42857C7.85714 7.27702 7.91735 7.13167 8.02451 7.02451C8.13167 6.91735 8.27702 6.85714 8.42857 6.85714ZM18.7143 7.42857H19.4286C19.5422 7.42857 19.6512 7.47372 19.7316 7.5541C19.812 7.63447 19.8571 7.74348 19.8571 7.85714V9.28571C19.8571 9.39938 19.812 9.50839 19.7316 9.58876C19.6512 9.66913 19.5422 9.71429 19.4286 9.71429H18.7143V7.42857ZM19.4286 10.8571H18.7143V13.1429H19.4286C19.5422 13.1429 19.6512 13.0977 19.7316 13.0173C19.812 12.937 19.8571 12.828 19.8571 12.7143V11.2857C19.8571 11.1721 19.812 11.063 19.7316 10.9827C19.6512 10.9023 19.5422 10.8571 19.4286 10.8571ZM18.7143 14.2857H19.4286C19.5422 14.2857 19.6512 14.3309 19.7316 14.4112C19.812 14.4916 19.8571 14.6006 19.8571 14.7143V16.1429C19.8571 16.2565 19.812 16.3655 19.7316 16.4459C19.6512 16.5263 19.5422 16.5714 19.4286 16.5714H18.7143V14.2857Z"
        fill="#999999"
      />
    </svg>
  );
};

export default StatementSvg;
