import React from "react";

const Reset = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M4.72727 15.2727H19.2727V16.7273H4.72727V15.2727ZM5.56364 12.3273L6.18182 11.2509L6.8 12.3273L7.74545 11.7818L7.12727 10.7055H8.36364V9.61455H7.12727L7.74545 8.54545L6.8 8L6.18182 9.06909L5.56364 8L4.61818 8.54545L5.23636 9.61455H4V10.7055H5.23636L4.61818 11.7818L5.56364 12.3273ZM10.4364 11.7818L11.3818 12.3273L12 11.2509L12.6182 12.3273L13.5636 11.7818L12.9455 10.7055H14.1818V9.61455H12.9455L13.5636 8.54545L12.6182 8L12 9.06909L11.3818 8L10.4364 8.54545L11.0545 9.61455H9.81818V10.7055H11.0545L10.4364 11.7818ZM20 9.61455H18.7636L19.3818 8.54545L18.4364 8L17.8182 9.06909L17.2 8L16.2545 8.54545L16.8727 9.61455H15.6364V10.7055H16.8727L16.2545 11.7818L17.2 12.3273L17.8182 11.2509L18.4364 12.3273L19.3818 11.7818L18.7636 10.7055H20V9.61455Z"
        fill="#999999"
      />
    </svg>
  );
};

export default Reset;
