import React from "react"

const Linkedin = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="26"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M8.5 16C12.9183 16 16.5 12.4183 16.5 8C16.5 3.58172 12.9183 0 8.5 0C4.08172 0 0.5 3.58172 0.5 8C0.5 12.4183 4.08172 16 8.5 16Z"
        fill="#062983"
      />
      <path
        d="M13.2792 8.6434V11.9416H11.367V8.86444C11.367 8.09179 11.0909 7.56414 10.3985 7.56414C9.87016 7.56414 9.55628 7.91938 9.41767 8.26335C9.36733 8.38627 9.35435 8.55698 9.35435 8.72939V11.9415H7.44198C7.44198 11.9415 7.46765 6.72974 7.44198 6.19026H9.35449V7.00526C9.35064 7.01168 9.34522 7.01795 9.3418 7.02409H9.35449V7.00526C9.60862 6.61423 10.0618 6.05521 11.0779 6.05521C12.336 6.05521 13.2792 6.8772 13.2792 8.6434ZM5.44148 3.41797C4.78734 3.41797 4.35938 3.84736 4.35938 4.41152C4.35938 4.96369 4.77493 5.40549 5.41638 5.40549H5.42879C6.09576 5.40549 6.51047 4.96369 6.51047 4.41152C6.49778 3.84736 6.09576 3.41797 5.44148 3.41797ZM4.47303 11.9416H6.38469V6.19026H4.47303V11.9416Z"
        fill="#F1F2F2"
      />
    </svg>
  )
}

export default Linkedin
