import React from "react";

const ArrowUp = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M4.31957 9.98058L10.7989 3.50121C10.8993 3.40054 11.0185 3.32066 11.1497 3.26615C11.281 3.21165 11.4217 3.18359 11.5639 3.18359C11.706 3.18359 11.8467 3.21165 11.978 3.26615C12.1093 3.32066 12.2285 3.40054 12.3288 3.50121L18.8082 9.98058C19.011 10.1835 19.125 10.4586 19.125 10.7455C19.125 11.0324 19.011 11.3076 18.8082 11.5104C18.6053 11.7133 18.3301 11.8273 18.0432 11.8273C17.7563 11.8273 17.4812 11.7133 17.2783 11.5104L12.6429 6.87499L12.6429 20.1037C12.6429 20.3901 12.5291 20.6648 12.3266 20.8673C12.124 21.0698 11.8494 21.1836 11.563 21.1836C11.2766 21.1836 11.0019 21.0698 10.7994 20.8673C10.5968 20.6648 10.4831 20.3901 10.4831 20.1037L10.4831 6.87499L5.84762 11.5113C5.64475 11.7142 5.3696 11.8282 5.08269 11.8282C4.79579 11.8282 4.52064 11.7142 4.31777 11.5113C4.1149 11.3085 4.00092 11.0333 4.00093 10.7464C4.00093 10.4595 4.1149 10.1844 4.31777 9.98148L4.31957 9.98058Z"
        fill="#E46C0B"
      />
    </svg>
  );
};

export default ArrowUp;
