import React from "react";

const Growth = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M21.918 6.80359C21.8165 6.55924 21.6223 6.36507 21.378 6.26359C21.2578 6.21235 21.1287 6.18517 20.998 6.18359H15.998C15.7328 6.18359 15.4784 6.28895 15.2909 6.47649C15.1033 6.66402 14.998 6.91838 14.998 7.18359C14.998 7.44881 15.1033 7.70316 15.2909 7.8907C15.4784 8.07824 15.7328 8.18359 15.998 8.18359H18.588L12.998 13.7736L9.70799 10.4736C9.61502 10.3799 9.50442 10.3055 9.38256 10.2547C9.2607 10.2039 9.13 10.1778 8.99799 10.1778C8.86597 10.1778 8.73527 10.2039 8.61341 10.2547C8.49155 10.3055 8.38095 10.3799 8.28799 10.4736L2.28799 16.4736C2.19426 16.5666 2.11986 16.6772 2.06909 16.799C2.01833 16.9209 1.99219 17.0516 1.99219 17.1836C1.99219 17.3156 2.01833 17.4463 2.06909 17.5682C2.11986 17.69 2.19426 17.8006 2.28799 17.8936C2.38095 17.9873 2.49155 18.0617 2.61341 18.1125C2.73527 18.1633 2.86597 18.1894 2.99799 18.1894C3.13 18.1894 3.2607 18.1633 3.38256 18.1125C3.50442 18.0617 3.61502 17.9873 3.70799 17.8936L8.99799 12.5936L12.288 15.8936C12.3809 15.9873 12.4915 16.0617 12.6134 16.1125C12.7353 16.1633 12.866 16.1894 12.998 16.1894C13.13 16.1894 13.2607 16.1633 13.3826 16.1125C13.5044 16.0617 13.615 15.9873 13.708 15.8936L19.998 9.59359V12.1836C19.998 12.4488 20.1033 12.7032 20.2909 12.8907C20.4784 13.0782 20.7328 13.1836 20.998 13.1836C21.2632 13.1836 21.5176 13.0782 21.7051 12.8907C21.8926 12.7032 21.998 12.4488 21.998 12.1836V7.18359C21.9964 7.05292 21.9692 6.92382 21.918 6.80359Z"
        fill="#059D02"
      />
    </svg>
  );
};

export default Growth;
